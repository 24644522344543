import styles from './LatestBlogItems.module.scss';
import type { LatestBlogItem } from 'behavior/blog/latestItems';
import BlogItemTile from './tile';

type Props = {
  id: string;
  items: LatestBlogItem[];
};

const GridLatestBlogItems = ({ id, items }: Props) => (
  <section id={id}>
    <div role="presentation" className={styles.grid}>
      {items.map(item => (
        <BlogItemTile key={item.id} item={item} />
      ))}
    </div>
  </section>
);

export default GridLatestBlogItems;