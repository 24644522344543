import styles from './LatestBlogItems.module.scss';
import type { LatestBlogItem } from 'behavior/blog/latestItems';
import ReactResizeDetector from 'react-resize-detector';
import BlogItemTilesSlider from './BlogItemTilesSlider';
import { memo } from 'react';

const tileMinWidth = +styles.tileMinWidth;

type Props = {
  id: string;
  items: LatestBlogItem[];
};

const SliderLatestBlogItems = ({ id, items }: Props) => (
  <section id={id}>
    <ReactResizeDetector handleWidth>
      {({ width = tileMinWidth }) => (
        <BlogItemTilesSlider
          items={items}
          tilesPerView={Math.floor(width / tileMinWidth) || 1}
        />
      )}
    </ReactResizeDetector>
  </section>
);

export default memo(SliderLatestBlogItems);