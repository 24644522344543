import type { LatestBlogItem } from 'behavior/blog/latestItems';
import { memo } from 'react';
import { LazyImage } from 'components/primitives/responsiveImages';
import type { ComponentOrHtmlProps } from 'utils/react';

type Props = {
  item: LatestBlogItem;
} & ComponentOrHtmlProps<typeof LazyImage>;

const BlogItemThumbnail = ({ item, ...props }: Props) => {
  if (!item.image)
    return null;

  return <LazyImage src={item.image} title={item.title} alt={item.title} {...props} />;
};

export default memo(BlogItemThumbnail);
