import { memo } from 'react';
import { routesBuilder } from 'routes';
import { useSanaTexts } from 'components/sanaText';
import { useCurrentRouteAsBackTo } from 'utils/hooks';
import { Link } from 'components/primitives/links';

const CASearch = () => {
  const [attachmentSearchHeaderText] = useSanaTexts(['AttachmentSearch_HeaderText']).texts;

  const backTo = useCurrentRouteAsBackTo();

  return (
    <Link to={routesBuilder.forAttachmentSearchMain} options={{ backTo }}>
        {attachmentSearchHeaderText}
    </Link>
  );
};
export default memo(CASearch);
