import styles from './LatestBlogItems.module.scss';
import type { LatestBlogItem } from 'behavior/blog/latestItems';
import { Slider } from 'components/primitives/slider';
import { useMemo, memo } from 'react';
import BlogItemTile from './tile';

type Props = {
  items: LatestBlogItem[];
  tilesPerView: number;
};

const BlogItemTilesSlider = ({ items, tilesPerView }: Props) => {
  const tiles = useMemo(() => items.map(item => (
    <BlogItemTile key={item.id} item={item} />
  )), [items]);

  if (!tiles.length)
    return null;

  const slider = tiles.length > tilesPerView
    ? <Slider items={tiles} tilesPerView={tilesPerView} showArrows={false} />
    : <div className={styles.tiles}>{tiles}</div>;

  const style = { '--tile-width': 100 / tilesPerView + '%' } as React.CSSProperties;

  return (
    <div role="presentation" className={styles.slider} onDragStart={preventDefault} style={style}>
      {slider}
    </div>
  );
};

export default memo(BlogItemTilesSlider);

function preventDefault(e: React.DragEvent<HTMLDivElement>) {
  e.preventDefault();
}