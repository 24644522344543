import styles from '../LatestBlogItems.module.scss';
import type { LatestBlogItem } from 'behavior/blog/latestItems';
import { memo } from 'react';
import { Link } from 'components/primitives/links';
import { routesBuilder } from 'routes';
import BlogItemThumbnail from './BlogItemThumbnail';
import { getToLocaleDate } from 'utils/format';
import { useSelector } from 'react-redux';

type Props = {
  item: LatestBlogItem;
};

const BlogItemTile = ({ item }: Props) => {
  const blogItemRouteData = routesBuilder.forBlogItem(item.id);
  const cultureName = useSelector(state => state.localization.currentLanguage).cultureName;
  const formatDateUTC = cultureName ? getToLocaleDate(cultureName, 'UTC') : null;
  
  return (
    <article aria-label={item.title} className={styles.item}>
      <div className={styles.content}>
        { 
          item.image && <div className={styles.image}>
            <Link draggable="false" url={item.url} to={blogItemRouteData}>
              <BlogItemThumbnail draggable="false" item={item} />
            </Link>
          </div>
        }
        <div className={styles.info}>
          <Link draggable="false" url={item.url} to={blogItemRouteData}>
            {item.title}
          </Link>
          <div>
            {formatDateUTC?.(item.publishDate)}
          </div>
        </div>
      </div>
    </article>
  );
};

export default memo(BlogItemTile);