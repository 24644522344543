import { memo } from 'react';
import PropTypes from 'prop-types';
import { useSanaTexts } from 'components/sanaText';
import BlogSearchBox from './BlogSearchBox';
import { RouteName } from 'routes';
import { useRoutes } from 'components/primitives/route';
import { defaultSearchParams } from './constants';
import { makeSimpleText } from 'utils/render';
import { useIsPreview } from 'components/objects/preview';
import { ThemeFontSize } from 'behavior/theme';
import { ButtonLayoutOption } from './constants';

const searchRoute = { routeName: RouteName.Blog, params: { q: defaultSearchParams } };
const defaultOptions = {
  boxThemeFontSize: ThemeFontSize.Regular,
  buttonLayout: ButtonLayoutOption.IconOnly,
  buttonThemeFontSize: ThemeFontSize.Regular,
  suggestionsThemeFontSize: ThemeFontSize.Regular,
};

const BlogSearch = ({ id, className, onFocus, onBlur, isDesignerMode, options = defaultOptions, style }) => {
  const isPreview = useIsPreview();
  const [routePath] = useRoutes([searchRoute]);
  const [placeholder, ariaLabelText, ariaRoleDescriptionText, searchText, searchTitle] = useSanaTexts([
    !options.placeholder && 'BlogSearch_SearchPrefix',
    'BlogSearch_SearchBar_AriaLabelText',
    'BlogSearch_SearchBar_AriaRoleDescriptionText',
    'ButtonText_BlogSearch',
    'ButtonAltText_BlogSearch',
  ]).texts;
  const disabled = isDesignerMode || (!routePath && isPreview);

  if (!routePath && !disabled)
    return null;

  return (
    <BlogSearchBox
      inputId={`${id}_input`}
      placeholder={options.placeholder || makeSimpleText(placeholder)}
      ariaLabelText={ariaLabelText}
      ariaRoleDescriptionText={ariaRoleDescriptionText}
      routePath={routePath}
      searchText={searchText}
      searchTitle={searchTitle}
      className={className}
      onFocus={onFocus}
      onBlur={onBlur}
      disabled={disabled}
      options={options}
      style={style}
    />
  );
};

BlogSearch.propTypes = {
  id: PropTypes.string.isRequired,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  className: PropTypes.string,
  isDesignerMode: PropTypes.bool,
  options: PropTypes.shape({
    placeholder: PropTypes.string,
  }),
  style: PropTypes.object,
};

export default memo(BlogSearch);
