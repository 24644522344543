import { useEffect } from 'react';
import GridLatestBlogItems from './GridLatestBlogItems';
import SliderLatestBlogItems from './SliderLatestBlogItems';
import { registerLatestBlogItems, unregisterLatestBlogItems } from 'behavior/blog/latestItems';
import { useDispatch, useSelector } from 'react-redux';

const enum LatestBlogItemsDisplayMode {
  Grid = 0,
  Slider = 1,
}

type Props = {
  id: string;
  model: {
    displayMode: LatestBlogItemsDisplayMode;
    blogItemsAmountToDisplay: number;
  };
};

const LatestBlogItemsBlock = ({ id, model }: Props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(registerLatestBlogItems(id, { amount: model.blogItemsAmountToDisplay }));
    return () => void dispatch(unregisterLatestBlogItems(id));
  }, [id, model.blogItemsAmountToDisplay]);

  const items = useSelector(state => state.blog.latestItems[id]?.items);

  if (!items || !items.length)
    return null;

  switch (model.displayMode) {
    case LatestBlogItemsDisplayMode.Slider:
      return <SliderLatestBlogItems id={`SliderLatestBlogItems_${id}`} items={items} />;
    default:
      return <GridLatestBlogItems id={`GridLatestBlogItems_${id}`} items={items} />;
  }
};

export default LatestBlogItemsBlock;