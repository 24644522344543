import styles from 'components/objects/searchBarBlock/Search.module.scss';
import PropTypes from 'prop-types';
import { getThemeFontSizeClassName } from 'components/theme';

const SuggestionItem = ({ suggestion, options }) => (
  <span className={`${styles.suggestItem} ${getThemeFontSizeClassName(options.suggestionsThemeFontSize)}`}>
    <span className={styles.text}>
      {suggestion.highlightedText}
    </span>
  </span>
);

SuggestionItem.propTypes = {
  suggestion: PropTypes.shape({
    highlightedText: PropTypes.node.isRequired,
  }).isRequired,
  options: PropTypes.shape({
    suggestionsThemeFontSize: PropTypes.string.isRequired,
  }).isRequired,
};

export default SuggestionItem;
