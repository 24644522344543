import styles from 'components/objects/searchBarBlock/Search.module.scss';
import { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { useOnChange } from 'utils/hooks';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Autosuggest, highlightStringBySpans } from 'components/primitives/autosuggest';
import { escapeRegexCharacters } from 'utils/helpers';
import { debounce } from 'lodash';
import SuggestionItem from './SuggestionItem';
import { SearchContainerContext } from './SearchContainerContext';
import SuggestionsContainer from './SuggestionsContainer';
import { requestBlogSuggestions, blogSuggestionsCleared } from 'behavior/blog/suggestions';
import { navigateTo } from 'behavior/events';
import { RouteName } from 'routes';
import { defaultSearchParams } from './constants';
import SearchButton from './SearchButton';
import { getThemeFontSizeClassName } from 'components/theme';

const defaultDependencies = [];

const BlogSearchBox = ({
  placeholder,
  ariaLabelText,
  ariaRoleDescriptionText,
  searchText,
  searchTitle,
  requestBlogSuggestions,
  blogSuggestionsCleared,
  suggestions,
  navigateTo,
  routePath,
  routeData,
  prevRouteData,
  className = '',
  onFocus,
  onBlur,
  inputId,
  disabled,
  options,
  style,
}) => {
  const formRef = useRef(null);
  const highlightedSuggestionRef = useRef();
  const [maxHeight, setMaxHeight] = useState('');
  const [value, setValue] = useState(routeData.params.q ?? '');

  useOnChange(() => {
    const routeName = routeData?.routeName;
    if (routeName === RouteName.Blog && routeData.params.q) {
      setValue(routeData.params.q);
      return;
    }

    if (!value)
      return;

    const prevRouteName = prevRouteData?.routeName;

    if (prevRouteName && prevRouteName === routeName && prevRouteData.params.language !== routeData.params.language)
      return;

    if (prevRouteName === RouteName.Blog && routeName === RouteName.BlogItemPage)
      return;

    setValue('');
  }, [routeData, prevRouteData], false);

  useEffect(() => {
    const resizeHandler = debounce(() => {
      if (!formRef.current)
        return;

      setMaxHeight(calculateMaxHeight(formRef.current));
    }, 250);

    window.addEventListener('resize', resizeHandler);
    return () => window.removeEventListener('resize', resizeHandler);
  }, [formRef.current]);

  const search = useMemo(() => debounce(value => !disabled && requestBlogSuggestions(value), 250), [disabled]);

  const onFetchRequested = useCallback(({ value, reason }) => {
    setMaxHeight(calculateMaxHeight(formRef.current));
    if (reason === 'input-changed' && value.length > 1)
      search(value);
  }, defaultDependencies);

  const onSelected = useCallback((event, { suggestion }) => {
    event.preventDefault();
    blogSuggestionsCleared();
    setValue('');
    document.getElementById('layout').focus();

    navigateTo(suggestion.routeData, suggestion.url);
  }, defaultDependencies);

  const onSubmit = useCallback(event => {
    event.preventDefault();

    if (disabled)
      return;

    blogSuggestionsCleared();
    const encodedValue = encodeURIComponent(value);
    const searchURL = routePath.replace(defaultSearchParams, encodedValue);

    navigateTo({ routeName: RouteName.Blog, params: { q: value } }, searchURL);

    document.getElementById('layout').focus();
  }, [routePath, value, disabled]);

  const onHighlighted = ({ suggestion }) => {
    highlightedSuggestionRef.current = suggestion;
  };

  const renderItem = useCallback(suggestion => (
    <SuggestionItem suggestion={suggestion} options={options} />
  ), defaultDependencies);

  const onChange = useCallback((_event, { newValue }) => setValue(newValue), defaultDependencies);

  const onKeyDown = e => {
    if (highlightedSuggestionRef.current && e.key === 'Tab') {
      onSelected(e, { item: highlightedSuggestionRef.current });
      e.preventDefault();
    }
  };

  const inputProps = {
    id: inputId,
    placeholder,
    value,
    onChange,
    onFocus,
    onBlur,
    onKeyDown,
    name: 'q',
    disabled,
  };

  const memorizedSuggestions = useMemo(() => getSuggestions(value, suggestions), [suggestions]);
  const searchPathWithoutParams = useMemo(() => routePath ? routePath.split('?')[0] : '', [routePath]);

  return (
    <div style={style} className={`${styles.searchBox} ${className} ${getThemeFontSizeClassName(options.boxThemeFontSize)}`}>
      <form
        role="search"
        method="get"
        action={searchPathWithoutParams}
        onSubmit={onSubmit}
        ref={formRef}
        aria-label={ariaLabelText}
        aria-roledescription={ariaRoleDescriptionText}
      >
        <SearchContainerContext.Provider value={{ maxHeight }}>
          <Autosuggest
            suggestions={memorizedSuggestions}
            onFetchRequested={onFetchRequested}
            onClearRequested={blogSuggestionsCleared}
            onSelected={onSelected}
            onHighlighted={onHighlighted}
            getItemValue={getItemValue}
            renderItem={renderItem}
            renderItemsContainer={SuggestionsContainer}
            inputProps={inputProps}
            theme={styles}
            id={inputId} // Suffix to be added to 'react-autowhatever-' to resolve ID duplicates conflict.
          />
        </SearchContainerContext.Provider>
        <SearchButton text={searchText} title={searchTitle} options={options} />
      </form>
    </div>
  );
};

const routeDataPropType = PropTypes.shape({
  routeName: PropTypes.string,
  params: PropTypes.shape({
    q: PropTypes.string,
    language: PropTypes.number,
  }),
});

BlogSearchBox.propTypes = {
  placeholder: PropTypes.string,
  ariaLabelText: PropTypes.string,
  ariaRoleDescriptionText: PropTypes.string,
  searchText: PropTypes.string,
  searchTitle: PropTypes.string,
  requestBlogSuggestions: PropTypes.func.isRequired,
  blogSuggestionsCleared: PropTypes.func.isRequired,
  suggestions: PropTypes.array.isRequired,
  routePath: PropTypes.string,
  navigateTo: PropTypes.func.isRequired,
  noImageUrl: PropTypes.string,
  routeData: routeDataPropType,
  prevRouteData: routeDataPropType,
  className: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  inputId: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  options: PropTypes.shape({
    boxThemeFontSize: PropTypes.string.isRequired,
  }).isRequired,
  style: PropTypes.object,
};

const mapStateToProps = ({
  blog: { suggestions },
  routing: { routeData, previous },
}) => ({
  suggestions,
  routeData,
  prevRouteData: previous?.routeData,
});

export default connect(mapStateToProps, { requestBlogSuggestions, blogSuggestionsCleared, navigateTo })(BlogSearchBox);

function getSuggestions(value, suggestions) {
  const escapedValue = escapeRegexCharacters(value.trim());
  const regex = new RegExp(`(${escapedValue})`, 'gi');

  return suggestions.map(suggestion => ({
    ...suggestion,
    highlightedText: highlightStringBySpans(suggestion.title, regex, styles.highlight),
  }));
}

function getItemValue(suggestion) {
  return suggestion.title;
}

function calculateMaxHeight(form) {
  const innerHeight = window.innerHeight;

  return innerHeight - form.getBoundingClientRect().bottom + 'px';
}
