import styles from 'components/objects/searchBarBlock/Search.module.scss';
import { memo } from 'react';
import PropTypes from 'prop-types';
import { SearchButtonIcon } from 'components/primitives/icons';
import { getThemeFontSizeClassName } from 'components/theme';
import { ButtonLayoutOption } from './constants';

const SearchButton = ({ text, title, options, ...props }) => {
  let iconWithText;
  
  switch (options.buttonLayout) {
    case ButtonLayoutOption.TextOnly:
      iconWithText = text;
      break;
    case ButtonLayoutOption.TextRightOfIcon:
      iconWithText = <><SearchButtonIcon className={styles.searchIcon} aria-hidden /> {text}</>;
      break;
    case ButtonLayoutOption.TextLeftOfIcon:
      iconWithText = <>{text} <SearchButtonIcon className={styles.searchIcon} aria-hidden /></>;
      break;
    case ButtonLayoutOption.TextBelowIcon:
      iconWithText = (
        <>
          <SearchButtonIcon className={styles.searchIcon} aria-hidden />
          <span className={styles.block}>{text}</span>
        </>
      );
      break;
    case ButtonLayoutOption.TextOverIcon:
      iconWithText = (
        <>
          <span className={styles.block}>{text}</span>
          <SearchButtonIcon className={styles.searchIcon} aria-hidden />
        </>
      );
      break;
    default:
      iconWithText = <SearchButtonIcon className={styles.searchIcon} aria-hidden />;
      break;
  }

  return (
    <button
      type="submit"
      className={`${styles.submit} ${getThemeFontSizeClassName(options.buttonThemeFontSize)}`}
      title={title}
      {...props}
    >
      {iconWithText}
    </button>
  );
};

SearchButton.propTypes = {
  text: PropTypes.string,
  title: PropTypes.string,
  options: PropTypes.shape({
    buttonLayout: PropTypes.number.isRequired,
    buttonThemeFontSize: PropTypes.string.isRequired,
  }).isRequired,
};

export default memo(SearchButton);
